<template>
  <div>
		<loading-overlay :active="loading" :is-full-page="true" loader="bars"/>
    <CRow>
      <CCol sm="12" class="d-flex justify-content-end py-2">
      	<CButton
          color="add"
          @click="(isEdit=false, ModalActive=true)"
          v-c-tooltip="{
            content: $t('label.classification'),
            placement: 'top-end'
          }"
        >
          <CIcon name="cil-playlist-add"/>&nbsp;{{$t('label.nueva')}}
        </CButton>
      </CCol>
      <CCol sm="12">
        <dataTableExtended
          class="align-center-row-datatable"
          :items="computedList"
          :fields="fields"
          column-filter
          :items-per-page-select="tableText.itemsPerPageText"
          :items-per-page="5"
          pagination
          sorter
          :tableFilter="tableText.tableFilterText"
          :noItemsView="tableText.noItemsViewText"
        >
          <template #Status="{ item }">
            <td class="text-center">
              <CBadge :color="getBadge(item.Status)">
                {{ $t('label.'+item.Status) }}
              </CBadge>
            </td>
          </template>
          <template #options="{ item }">
            <td class="text-center">
              <CButton
                size="sm"
                color="edit"
                @click="EditClassification(item)"
                v-c-tooltip="{
                  content: `${$t('label.edit')} ${$t('label.classification')}`,
                  placement: 'top-end'
                }"
              >
                <CIcon name="pencil" />
              </CButton>
            </td>
          </template>
        </dataTableExtended>
      </CCol>
    </CRow>
		<ModalClassification
			:modal="ModalActive"
			:isEdit="isEdit"
			:EventGpoItem="EventGpoItem"
			@Close-Modal="(ModalActive=false, EventGpoItem={})"
			@List-Update="getEventGpoList"
		/>
  </div>
</template>
<script>
import { DateFormater } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
import ModalClassification from "./modal-classification";

function data() {
  return {
    EventGpoList: [],
		ModalActive: false,
		EventGpoItem: {},
		isEdit: false,
    loading: false,
  };
}

//methods
function getEventGpoList() {
  this.loading = true;
  this.$http.get('EventGpo-list')
    .then((response) => {
      let List = response.data.data
      if (List&&List.length!=0) {
        this.EventGpoList = List;
      }
      this.loading = false;
    }).catch((err) => {
      this.loading = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    });
}

function EditClassification(item) {
	this.isEdit = true;
	this.EventGpoItem = item;
	this.ModalActive = true;
}

//computed
function fields(){
  return [
     { label: '#', key: 'Nro', _classes: 'text-center', _style: 'width:3%; text-align:center', filter: false},
     { label: `${this.$t('label.classification')} (ES)`, key: 'EventGpoNameEs', _classes: 'text-center', _style: 'width:22%;',},
     { label: `${this.$t('label.classification')} (EN)`, key: 'EventGpoNameEn', _classes: 'text-center', _style: 'width:22%;',},
     { label: this.$t('label.user'), key: 'TransaLogin', _classes: 'text-center', _style:'width:15%' },
     { label: this.$t('label.date'), key: 'FormatedDate',_classes:'text-center', _style:'width:20%' },
     { label: this.$t('label.status'), key: 'Status', _classes: 'text-center text-center', _style:'width:15%' },
     { label: '', key: 'options', _style: 'min-width:45px;', sorter: false, filter: false },
  ];
}

function computedList() {
  return this.EventGpoList.map((item) => {
    return {
      ...item,
      _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
      FormatedDate: item.TransaRegDate
        ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
        : 'N/A',
    };
  });
}

export default {
  name: 'classification-index',
	props: {
		Tab: {
			type: Number,
			default: 0,
		}
	},
  components: { 
		ModalClassification,
  },
  data,
  mixins: [GeneralMixin],
  methods: {
		EditClassification,
    getEventGpoList,
  },
  computed: {
    fields,
    computedList,
  },
  mounted: getEventGpoList,
	watch: {
		Tab: function (val) {
			if (val==0) {
				this.getEventGpoList();
			}
		}
	}
};
</script>