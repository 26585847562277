var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('loading-overlay',{attrs:{"active":_vm.loading,"is-full-page":true,"loader":"bars"}}),_c('CRow',[_c('CCol',{staticClass:"d-flex justify-content-end py-2",attrs:{"sm":"12"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
            content: _vm.$t('label.classification'),
            placement: 'top-end'
          }),expression:"{\n            content: $t('label.classification'),\n            placement: 'top-end'\n          }"}],attrs:{"color":"add"},on:{"click":function($event){(_vm.isEdit=false, _vm.ModalActive=true)}}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_vm._v(" "+_vm._s(_vm.$t('label.nueva'))+" ")],1)],1),_c('CCol',{attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"items":_vm.computedList,"fields":_vm.fields,"column-filter":"","items-per-page-select":_vm.tableText.itemsPerPageText,"items-per-page":5,"pagination":"","sorter":"","tableFilter":_vm.tableText.tableFilterText,"noItemsView":_vm.tableText.noItemsViewText},scopedSlots:_vm._u([{key:"Status",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)]}},{key:"options",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                  content: `${_vm.$t('label.edit')} ${_vm.$t('label.classification')}`,
                  placement: 'top-end'
                }),expression:"{\n                  content: `${$t('label.edit')} ${$t('label.classification')}`,\n                  placement: 'top-end'\n                }"}],attrs:{"size":"sm","color":"edit"},on:{"click":function($event){return _vm.EditClassification(item)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1)],1)]}}])})],1)],1),_c('ModalClassification',{attrs:{"modal":_vm.ModalActive,"isEdit":_vm.isEdit,"EventGpoItem":_vm.EventGpoItem},on:{"Close-Modal":function($event){(_vm.ModalActive=false, _vm.EventGpoItem={})},"List-Update":_vm.getEventGpoList}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }